import React from 'react';
import { EnvironmentUrls } from 'Roblox';

const FriendsCarouselHeader = ({
  friendsCount,
  translate
}: {
  friendsCount: number | null;
  translate: (key: string) => string;
}): JSX.Element => {
  const friendsCountString = `(${friendsCount ?? 0})`;

  return (
    <div className='container-header people-list-header'>
      {friendsCount == null ? (
        <h2>{translate('Heading.Friends')}</h2>
      ) : (
        <h2>
          {translate('Heading.Friends')}
          <span className='friends-count'>{friendsCountString}</span>
        </h2>
      )}
      <a
        href={`${EnvironmentUrls.websiteUrl}/users/friends#!/friends`}
        className='btn-secondary-xs btn-more see-all-link-icon'>
        {translate('Heading.SeeAll')}
      </a>
    </div>
  );
};

export default FriendsCarouselHeader;
