import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import FriendsCarouselContainer from './containers/FriendsCarouselContainer';
import { getUrlUserId } from '../../../js/utils/appUtil';

import '../../../css/friendsCarousel/friendsCarousel.scss';

const friendsCarouselContainer = (): HTMLElement | null =>
  document.getElementById('friends-carousel-container');

const userDataMetaTag = document.querySelector('meta[name="user-data"]');
const authenticatedUserId: number = (userDataMetaTag
  ? userDataMetaTag.getAttribute('data-userid')
  : 0) as number;
const profileNumberString: string = getUrlUserId() as string;
const profileUserId: number = profileNumberString ? parseInt(profileNumberString, 10) : 0;

ready(() => {
  render(
    <FriendsCarouselContainer
      profileUserId={profileUserId}
      isOwnUser={profileUserId === authenticatedUserId}
    />,
    friendsCarouselContainer()
  );
});
