import React, { useEffect, useRef, useState } from 'react';
import FriendTile from './FriendTile';
import { TFriend } from '../types/friendsCarouselTypes';

const FriendsList = ({
  friendsList,
  isOwnUser,
  translate
}: {
  friendsList: TFriend[] | null;
  isOwnUser: boolean;
  translate: (key: string) => string;
}): JSX.Element => {
  const parentRef = useRef<HTMLElement | null>(null);
  const [visibleCount, setVisibleCount] = useState(friendsList != null ? friendsList.length : 0);

  useEffect(() => {
    if (parentRef.current) {
      const calculateVisibleCount = () => {
        const parentWidth = parentRef.current?.offsetWidth ?? 0;
        let currentX = 0;
        let count = 0;
        const childElements = Array.from(parentRef.current?.children ?? []) as HTMLElement[];

        childElements.forEach(child => {
          const childRightEdge = currentX + child.offsetWidth;
          if (childRightEdge <= parentWidth) {
            count += 1;
            currentX += child.offsetWidth;
          }
        });
        setVisibleCount(count);
      };

      calculateVisibleCount();

      const resizeObserver = new ResizeObserver(calculateVisibleCount);
      resizeObserver.observe(parentRef.current);

      return () => resizeObserver.disconnect();
    }
    return undefined;
  }, [friendsList]);

  return (
    <div>
      {friendsList == null ? (
        <span className='spinner spinner-default' />
      ) : (
        <div
          ref={el => {
            parentRef.current = el;
            return parentRef.current;
          }}
          className='friends-carousel-container'>
          {friendsList.map((item, index) => {
            return (
              <div key={item.id} style={{ opacity: index < visibleCount ? 1 : 0 }}>
                <FriendTile friend={item} translate={translate} isOwnUser={isOwnUser} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FriendsList;
